import React, { Component } from "react";

import SubHeadComponent from "../common/SubHead.component";
import ContComponent from "./Cont.component";

import * as CommonService from "../../../services/Common.service";
import * as CommonHelper from "../../../helpers/Common.helper";
import AnalyticsContext from "../../../context/AnalyticsContext";
import GuideTabComponent from "../common/GuideTab.component";
import PrepareComponent from '../../layout/Prepare.component'
import * as AuthHelper from '../../../helpers/Auth.helper'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {
  checkEventTarget,
  getEventApplyStatus,
  requestApplyEventNotAuth
} from '../../../services/Event.service'
import { decodeAES256 } from '../../../helpers/Crypto.helper'
import { getMyInfo } from '../../../services/User.service'

dayjs.locale('ko')

const kboTeamList = {
  63: "/img/ticket/emblem/hanwha.png",
  58: "/img/ticket/emblem/kia.png",
  62: "/img/ticket/emblem/kt.png",
  59: "/img/ticket/emblem/lg.png",
  60: "/img/ticket/emblem/lotte.png",
  64: "/img/ticket/emblem/nc.png",
  55: "/img/ticket/emblem/doosan.png",
  476: "/img/ticket/emblem/ssg.png",
  57: "/img/ticket/emblem/samsung.png",
  61: "/img/ticket/emblem/kiwoom.png"
}

class DetailContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);
    this.state = {
      subMenu: "guide",
      subMenuName: "KIA TIGERS 다문화 가족 야구 캠프 참가자 모집",
      title: '',
      contents: '',
      applicant_name: "",
      applicant_age: "",
      applicant_locale: "",
      applicant_phone: "",
      applicant_gender: "",
      applicant_birthday: "",
      applicant_shoe_size: "",
      parent_email: "",
      parent_phone: "",
      parent_relation: "",
      uniform_num: "",
      size_check_schedule: "",
      confirm: "",
      target_confirm: "",
      privacy_confirm: "",
      social_number_confirm: "",
      privacy_handle_confirm: "",
      record_confirm: "",
      userId: "",
      userName: "",
      mobile_no: "",
      isInit: false
    };
  }

  componentDidMount = async (_) => {
    alert("참가자 모집이 완료되어 이벤트 신청이 조기 종료되었습니다.\n감사합니다.");
    document.location.href = '/';
    return;
    if (!AuthHelper.checkHasLogin()) {
      alert("로그인 후 이용하실 수 있는 페이지입니다.");
      AuthHelper.removeLoginInfo();
      document.location.href = '/login?redirect=/event/youth/baseball-camp';
      return;
    } else {
      this.setState({ isInit: true });
    }

    // start 20230424 10:00 ~ 202304226 17:00
    const now = dayjs();
    // const start = dayjs('20241202 10:00', 'YYYYMMDD HH:mm');
    const start = dayjs('20241202 10:00', 'YYYYMMDD HH:mm');
    const end = dayjs('20241212 00:00', 'YYYYMMDD HH:mm');
    if (now.isBefore(start) || now.isAfter(end)) {
      alert('이벤트 기간이 아닙니다.');
      document.location.href = '/';
      return;
    }

    //
    // const alreadyApply = await getEventApplyStatus({eventIdx:4});
    // if(!alreadyApply) {
    //   alert('이미 신청이 완료되었습니다.')
    //   // history back
    //   document.location.href = '/';
    //   return;
    // }
    //
    // // const loginInfo = AuthHelper.getLoginInfo();
    const loginInfo = await getMyInfo();
    //
    const userId = loginInfo.user.memberId;
    const userName = loginInfo.user.memberName;
    const userMobile = loginInfo.user.mobpNo;

    // if(decodeAES256(userMobile) === "") {
    //   alert("등록된 전화번호가 없습니다.\n마이페이지에서 휴대폰 번호를 입력해주세요.")
    //   document.location.href = '/mypage';
    //   return;
    // }
    // //230130 주석
    this.setState({userId: userId, userName: userName, mobile_no: userMobile});
  };




  onChangeInputApplicantName  = (evt) => {
    this.setState({applicant_name: evt.target.value})
  }
  onChangeInputApplicantAge  = (evt) => {
    this.setState({applicant_age: evt.target.value})
  }
  onChangeInputApplicantLocale  = (evt) => {
    this.setState({applicant_locale: evt.target.value})
  }
  onChangeInputApplicantPhone  = (evt) => {
    this.setState({applicant_phone: evt.target.value})
  }
  onChangeInputApplicantBirthday  = (evt) => {
    this.setState({applicant_birthday: evt.target.value})
  }
  onChangeInputApplicantShoeSize  = (evt) => {
    this.setState({applicant_shoe_size: evt.target.value})
  }
  onChangeInputApplicantGender  = (evt) => {
    this.setState({applicant_gender: evt.target.value})
  }
  onChangeInputParentEmail  = (evt) => {
    this.setState({parent_email: evt.target.value})
  }
  onChangeInputParentPhone  = (evt) => {
    this.setState({parent_phone: evt.target.value})
  }
  onChangeInputParentRelation  = (evt) => {
    this.setState({parent_relation: evt.target.value})
  }
  onChangeInputUniformNum  = (evt) => {
    this.setState({uniform_num: evt.target.value})
  }
  onChangeInputSizeCheck  = (evt) => {
    this.setState({size_check_schedule: evt.target.value})
  }
  onChangeInputConfirm  = (evt) => {
    this.setState({confirm: evt.target.value})
  }
  onChangeInputTargetConfirm  = (evt) => {
    this.setState({target_confirm: evt.target.value})
  }
  onChangeInputPrivacyConfirm  = (evt) => {
    this.setState({privacy_confirm: evt.target.value})
  }
  onChangeInputSocialNumberConfirm  = (evt) => {
    this.setState({social_number_confirm: evt.target.value})
  }
  onChangeInputPrivateHandleConfirm  = (evt) => {
    this.setState({privacy_handle_confirm: evt.target.value})
  }
  onChangeInputRecordConfirm  = (evt) => {
    this.setState({record_confirm: evt.target.value})
  }

  onClickBtnRequest = async (_) => {
    const { applicant_name,
      applicant_age,
      applicant_locale,
      applicant_phone,
      applicant_birthday,
      applicant_shoe_size,
      applicant_gender,
      parent_email,
      parent_phone,
      parent_relation,
      uniform_num,
      size_check_schedule,
      confirm,
      target_confirm,
      privacy_confirm,
      social_number_confirm,
      privacy_handle_confirm,
      record_confirm } = this.state;

    const loginInfo = AuthHelper.getLoginInfo();
    console.log(loginInfo.user);
    const userId = loginInfo.user.memberId;
    const userNm = loginInfo.user.memberName;
    const userMobile = loginInfo.user.mobileNo;


    if(!userId) {
      alert('로그인 후 이용해주세요.');
      // AuthHelper.removeLoginInfo();
      // document.location.href = '/login?redirect=/event/youth/baseball-camp';
      return;
    }

    console.log(this.state);
    if (
        !applicant_name ||
        !applicant_phone ||
        !applicant_birthday ||
        !parent_email ||
        !parent_phone
    ) {
      alert('필수항목을 입력해주세요.');
      return;

    }


    const isValidPhoneNumber = phoneNumber => {
      const pattern = /^01[0-9]{9}$/;
      return pattern.test(phoneNumber.replace(/-/g, ''));
    }

    const isValidBirthday = birthday => {
      const pattern = /^[0-9]{4}.[0-9]{2}.[0-9]{2}$/;
      return pattern.test(birthday);
    }

    if(applicant_phone.length < 10 || !isValidPhoneNumber(applicant_phone) ) {
      alert('참가자 연락처를 올바르게 입력해주세요.\n예시) 01012345678');
      document.getElementById("applicant_phone").focus()
      return;
    }

    if(parent_phone.length < 10 || !isValidPhoneNumber(parent_phone) ) {
      alert('보호자 연락처를 올바르게 입력해주세요.\n예시) 01012345678');
      document.getElementById("parent_phone").focus()
      return;
    }

    if(applicant_birthday === "" || !isValidBirthday(applicant_birthday)) {
      alert('참가자 생년월일을 올바르게 입력해주세요.\n예시) 2000.01.01');
      document.getElementById("applicant_birthday").focus()
      return;
    }

    // if(applicant_age === "" || applicant_age === "none") {
    //   alert("참가자 연령을 선택해주세요.");
    //   document.getElementById("applicant_age").focus()
    //   return;
    // }

    if(applicant_gender === "" || applicant_gender === "none") {
      alert("참가자 성별을 선택해주세요.");
      document.getElementById("applicant_gender").focus()
      return;
    }

    if(applicant_locale === "" || applicant_locale === "none") {
      alert("참가자 거주지역을 선택해주세요.");
      document.getElementById("applicant_locale").focus()
      return;
    }
    // if(applicant_shoe_size === "" || applicant_shoe_size === "none") {
    //   alert("참가자 신발 사이즈를 선택해주세요.");
    //   document.getElementById("applicant_shoe_size").focus()
    //   return;
    // }
    if(parent_relation === "" || parent_relation === "none") {
      alert("보호자와의 관계를 선택해주세요.");
      document.getElementById("parent_relation").focus()
      return;
    }
    // if(size_check_schedule === "" || size_check_schedule === "none") {
    //   alert("광주-기아챔피언스필드 방문 일정을 선택해주세요.");
    //   document.getElementById("size_check_1").focus()
    //   return;
    // }


    // if(confirm === "") {
    //   alert("신청 내용을 확인해주세요.");
    //   document.getElementById("confirm_y").focus()
    //   return;
    // } else if(confirm === "N") {
    //   alert("신청 내용을 확인해주세요.");
    //   document.getElementById("confirm_y").focus()
    //   return;
    // }


    if(target_confirm === "") {
      alert("증빙 서류 확인 절차에 동의해주세요.");
      document.getElementById("target_confirm_y").focus()
      return;
    } else if(target_confirm === "N") {
      alert("증빙 서류 확인 절차에 동의해주세요.");
      document.getElementById("target_confirm_y").focus()
      return;
    }


    if(privacy_confirm === "") {
        alert("개인정보 수집 및 이용에 동의해주세요.");
        document.getElementById("privacy_confirm_y").focus()
        return;
    } else if(privacy_confirm === "N") {
        alert("개인정보 수집 및 이용에 동의해주세요.");
        document.getElementById("privacy_confirm_y").focus()
        return;
    }

    if(social_number_confirm === "") {
      alert("고유식별정보의 수집 · 이용에 동의해주세요");
      document.getElementById("social_number_y").focus()
      return;
    } else if(social_number_confirm === "N") {
      alert("고유식별정보의 수집 · 이용에 동의해주세요");
      document.getElementById("social_number_y").focus()
      return;
    }

    if(privacy_handle_confirm === "") {
      alert("개인정보 처리 위탁에 동의해주세요.");
      document.getElementById("private_handle_y").focus()
      return;
    } else if(privacy_handle_confirm === "N") {
      alert("개인정보 처리 위탁에 동의해주세요.");
      document.getElementById("private_handle_y").focus()
      return;
    }

    if(record_confirm === "") {
      alert("촬영 및 초상권 활용에 동의해주세요.");
      document.getElementById("record_confirm_y").focus()
      return;
    } else if(record_confirm === "N") {
      alert("촬영 및 초상권 활용에 동의해주세요.");
      document.getElementById("record_confirm_y").focus()
      return;
    }



    const check = window.confirm("신청 시 취소 및 수정이 불가합니다.\n신청을 하시겠습니까?")
    if(check) {
      const data = {

        eventIdx: 991,
        extData: {
            applicant_name,
            // applicant_age,
            applicant_locale,
            applicant_phone,
            applicant_birthday,
            // applicant_shoe_size,
            applicant_gender,
            parent_email,
            parent_phone,
            parent_relation,
            // uniform_num,
            // size_check_schedule,
            // confirm,
            target_confirm,
            privacy_confirm,
            social_number_confirm,
            privacy_handle_confirm,
            record_confirm,
            userId,
            userNm,
            userMobile
        }
      }
      const result = await requestApplyEventNotAuth(data)
      if(result.status) {
        alert('KIA TIGERS 다문화 가족 야구 캠프 신청이 완료되었습니다.');
        document.location.href = '/';
      } else {
        alert('지원에 실패하였습니다.\n관리자에게 문의해주세요.' + result.message);
      }
    }


  }



  render = (_) => (
    <div className="container">
      {/*<SubHeadComponent subMenuName={this.state.subMenuName} />*/}

      {/* 230127 주석 */}
      {/*{ !CommonHelper.checkIsOnWebview() && (*/}
      {/*  <GuideTabComponent*/}
      {/*    onClickBtnChangeMembership={this.onClickBtnChangeMembership}*/}
      {/*    tabNum={this.state.tabNum}*/}
      {/*  />*/}
      {/*)}*/}
      {/* 230130 추가 */}
      {/*<PrepareComponent />*/}

      {/* 230130 주석 */}
      {<ContComponent
        onChangeInputApplicantName={this.onChangeInputApplicantName}
        onChangeInputApplicantAge={this.onChangeInputApplicantAge}
        onChangeInputApplicantLocale={this.onChangeInputApplicantLocale}
        onChangeInputApplicantPhone={this.onChangeInputApplicantPhone}
        onChangeInputApplicantBirthday={this.onChangeInputApplicantBirthday}
        onChangeInputApplicantShoeSize={this.onChangeInputApplicantShoeSize}
        onChangeInputParentEmail={this.onChangeInputParentEmail}
        onChangeInputParentPhone={this.onChangeInputParentPhone}
        onChangeInputUniformNum={this.onChangeInputUniformNum}
        onChangeInputConfirm={this.onChangeInputConfirm}
        onChangeInputSizeCheck={this.onChangeInputSizeCheck}
        onChangeInputParentRelation={this.onChangeInputParentRelation}
        onChangeInputTargetConfirm={this.onChangeInputTargetConfirm}
        onChangeInputPrivacyConfirm={this.onChangeInputPrivacyConfirm}
        onChangeInputSocialNumberConfirm={this.onChangeInputSocialNumberConfirm}
        onChangeInputPrivateHandleConfirm={this.onChangeInputPrivateHandleConfirm}
        onChangeInputRecordConfirm={this.onChangeInputRecordConfirm}
        onChangeInputApplicantGender={this.onChangeInputApplicantGender}
        userId={this.state.userId}
        userName={this.state.userName}
        mobile_no={this.state.mobile_no}
        applicant_name={this.props.applicant_name}
        applicant_phone={this.props.applicant_phone}
        applicant_birthday={this.props.applicant_birthday}
        parent_email={this.props.parent_email}
        parent_phone={this.props.parent_phone}
        uniform_num={this.props.uniform_num}
        onClickBtnRequest={this.onClickBtnRequest}

      /> }
    </div>
  );
}

export default DetailContainer;
